<template>
	<div class="flex items-center justify-center vld-parent">

    <!-- Loading animation -->
    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0.7"
    />

		<div class="max-w-ld w-full p-10 rounded-lg shadow-md bg-white">
			<h2 class="mb-10 text-center text-3xl leading-9 font-extrabold text-primary-500">
        Редактирование данных
      </h2>

			<form class="mt-2" @submit.prevent="editClick()">

        <!-- ФИО -->
        <div class="relative flex">
          <div class="pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
            ФИО:
          </div>
          <input
            aria-label="Name"
            id="name"
            v-model="user.name"
            @change="$v.user.name.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.name.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.name.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Фамилия Имя Отчество"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.name.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.name.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.name.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.name.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.name.required">Требуется ввести как минимум фамилию и имя!</span>
              <span v-if="!$v.user.name.fio">Требуется ввести как минимум фамилию и имя!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Город -->
        <div class="relative mt-2 flex">
          <div class="pl-3 pt-2 pr-4 text-gray-500 text-sm text-left">
            Город:
          </div>
          <input
            aria-label="City"
            v-model="user.city"
            @change="$v.user.city.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.city.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.city.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Город"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.city.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.city.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.city.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.city.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.city.required">Введите город!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Speciality field -->
        <div class="flex mt-2">
          <div class="w-1/3 pl-3 pt-2 text-gray-500 text-sm text-left">
            Специальность:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
            <select
              class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
              rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
              focus:z-10 sm:text-sm sm:leading-5"
              v-model="user.speciality"
            >
              <option
                v-for="speciality in specialities"
                :key="speciality.id"
                :value="speciality.id"
              >
                {{ speciality.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Специальность (Другое) -->
        <div v-if="user.speciality === 18" class="relative mt-2">
          <input
            aria-label="Work"
            v-model="user.specialityName"
            @change="$v.user.specialityName.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.specialityName.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.specialityName.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Название специальности"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.specialityName.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.specialityName.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.specialityName.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.specialityName.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.specialityName.required">Заполните название специальности!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Position (должность) field -->
        <div class="flex my-2">
          <div class="w-1/3 pl-3 pt-2 pb-3 text-gray-500 text-sm text-left">
            Должность:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
            <select 
              class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
              text-gray-900 rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
              focus:z-10 sm:text-sm sm:leading-5"
              v-model="user.position"
            >
              <option value="" hidden>Выберите должность</option>
              <option
                v-for="position in positions"
                :key="position.id"
                :value="position.id"
              >
              {{ position.name }}
              </option>
            </select>
          </div>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="showError" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.position.required">Выберите должность!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Название должности при выборе "Другое" -->
        <div v-if="user.position === 0" class="relative mt-2 flex mb-3">
          <div class="w-1/3 pl-3 pt-2 text-gray-500 text-sm text-left">
            Введите должность:
          </div>
          <input
            aria-label="Work"
            v-model="positionName"
            @change="$v.positionName.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.positionName.$error,
              'focus:border-green-300 text-primary-500' : !$v.positionName.$error }"
            class="appearance-none relative block w-2/3 ml-5 px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Название должности"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.positionName.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.positionName.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.positionName.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.positionName.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.positionName.required">Заполните название должности!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Workplace (Место работы) field -->
        <div class="flex my-2">
          <div class="w-1/3 pl-3 pt-2 pb-3 text-gray-500 text-sm text-left">
            Место работы:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
            <select 
              class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
              text-gray-900 rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
              focus:z-10 sm:text-sm sm:leading-5"
              v-model="user.workplace"
            >
              <option value="" hidden>Выберите место работы</option>
              <option
                v-for="workplace in workplaces"
                :key="workplace.id"
                :value="workplace.id"
              >
              {{ workplace.name }}
              </option>
            </select>
          </div>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="showError" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.workplace.required">Выберите место работы!</span>
            </div>
          </collapse-transition>
        </div>
    
        <!-- Наименование учреждения -->
        <div class="relative mt-2 flex mb-3">
          <div class="w-1/3 pl-3 pt-2 text-gray-500 text-sm text-left">
            Наименование учреждения:
          </div>
          <input
            aria-label="Work"
            v-model="user.workAddress"
            @change="$v.user.workAddress.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.workAddress.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.workAddress.$error }"
            class="appearance-none relative block w-2/3 ml-5 px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Наименование учреждения"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.workAddress.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.workAddress.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.workAddress.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.workAddress.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.workAddress.required">Заполните наименование учреждения!</span>
            </div>
          </collapse-transition>
        </div>

        <button 
          type="submit"
          class="group mr-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Сохранить
        </button>

        <button
          @click="$xmodal.close()"
          class="group relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Отмена
        </button>

			</form>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, sameAs, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import { SlideXLeftTransition, CollapseTransition } from 'vue2-transitions'

const fio = helpers.regex('fio', /^[а-яa-zА-ЯA-Z\-]{1,}\s[а-яa-zА-ЯA-Z\-]{1,}(\s[а-яa-zА-ЯA-Z\-]{1,})?$/)

import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	name: 'EditModal',
  mixins: [validationMixin],
  components: { SlideXLeftTransition, CollapseTransition, Loading },
	data: function() {
		return {
      user: null,
      specialities: null,
      positions: null,
      workplaces: null,
      positionName: null,
      isLoading: false,
      showError: false
    }
	},
	computed: {
		...mapState({
      'user_old': state => state.auth.user
    })
  },
  validations: {
    user: {
      name: {
        required,
        fio
      },
      city: {
        required
      },
      workAddress: {
        required
      },
      speciality: {
        required
      },
      specialityName: {
        required: requiredIf(function() {
          return this.user.speciality === 18
        }),
        minLength: minLength(3)
      },
      position: {
        required
      },
      workplace: {
        required
      }
    },
    positionName: {
      required: requiredIf(function() {
        return this.user.position === 0
      })
    }
  },
  methods: {
    editClick() {
      if (this.$v.$invalid) {
        this.showError = true
        return
      }
      this.isLoading = true
      const data = {}
      if (this.user.name !== this.user_old.name) data.name = this.user.name
      if (this.user.city !== this.user_old.city) data.city = this.user.city
      if (this.user.workAddress !== this.user_old.workAddress) data.workAddress = this.user.workAddress
      if (this.user.speciality !== this.user_old.speciality.id) data.speciality = this.user.speciality
      if (this.user.workplace !== this.user_old.workplace.id) data.workplace = this.user.workplace

      if (this.user_old.position) {
        if (this.user.position === 0) {
          if (this.positionName !== this.user_old.position.name)
            data.position = this.positionName
        } else {
          if (this.user.position !== this.user_old.position.id)
            data.position = this.user.position
        }
      } else {
        if (this.user.position === 0)
          data.position = this.positionName
        else
          data.position = this.user.position
      }

      if (this.user.speciality === 18) {
        if (this.user.specialityName !== this.user_old.specialityName)
          data.specialityName = this.user.specialityName
      }

      this.$store.dispatch('auth/editUser', data)
        .then(() => {
          this.loginStatus = 'done'
          setTimeout(() => { this.$xmodal.close() }, 500)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created: function() {
    this.user = Object.assign({}, this.user_old)
    this.user.speciality = this.user_old.speciality.id

    this.user.position = this.user_old.position ? this.user_old.position.id : null
    if (this.user.position === 0)
      this.positionName = this.user_old.position.name

    this.user.workplace = this.user_old.workplace.id
    this.$store.getters.api.getSpecialities()
      .then(data => {
        this.specialities = data
      })
    this.$store.getters.api.getPositions()
      .then(data => {
        this.positions = data
        this.positions.push({ id: 0, name: 'Другое'})
      })
    this.$store.getters.api.getWorkplaces()
      .then(data => {
        this.workplaces = data
      })
  }
}
</script>